import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";

const CalculatorContainer = styled.section`
  padding: 80px 20px;
  background-color: #f9f9f9;
  color: #333;
  text-align: center;
  margin-top: 5em;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3em;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 200px;
    height: 3px;
    background-color: #00c6d7;
    margin: 10px auto;
    border-radius: 2px;
  }
`;

const StepContainer = styled(motion.div)`
  margin-bottom: 40px;
`;

const Question = styled.p`
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
`;

const Options = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
`;

const OptionButton = styled(motion.button)`
  background-color: #00c8ff;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #007bbd;
    transform: scale(1.05);
  }

  &.selected {
    background-color: #005f99;
  }
`;

const Summary = styled(motion.div)`
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
`;

const ContactForm = styled(motion.form)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-top: 20px;
`;

const Input = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background-color: #00c6d7;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #007bbd;
  }
`;

const ResetButton = styled.button`
  margin-top: 20px;
  background-color: #ff6666;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #cc0000;
  }
`;

const steps = [
  {
    question: "¿La app necesita registro de usuarios?",
    options: [
      { label: "Sí", value: 1350000 },
      { label: "No", value: 150000 },
    ],
  },
  {
    question: "¿Cuántas pantallas tendrá?",
    options: [
      { label: "Menos de 10", value: 1200000 },
      { label: "Más de 10", value: 3000000 },
    ],
  },
  {
    question: "¿Se necesita funcionalidad de chat?",
    options: [
      { label: "Sí", value: 1950000 },
      { label: "No", value: 150000 },
    ],
  },
  {
    question: "¿Se necesitan notificaciones push?",
    options: [
      { label: "Sí", value: 1200000 },
      { label: "No", value: 150000 },
    ],
  },
  {
    question: "¿Usará geolocalización?",
    options: [
      { label: "Sí", value: 1800000 },
      { label: "No", value: 150000 },
    ],
  },
  {
    question: "¿Qué nivel de diseño necesitas?",
    options: [
      { label: "Básico", value: 600000 },
      { label: "Personalizado", value: 1800000 },
    ],
  },
  {
    question: "¿Requiere integración con pagos?",
    options: [
      { label: "Sí", value: 1800000 },
      { label: "No", value: 150000 },
    ],
  },
  {
    question: "¿Se conectará con APIs externas?",
    options: [
      { label: "Sí", value: 1500000 },
      { label: "No", value: 150000 },
    ],
  },
  {
    question: "¿Se necesita un panel de administración?",
    options: [
      { label: "Sí", value: 2700000 },
      { label: "No", value: 150000 },
    ],
  },
  {
    question:
      "¿Tu app necesitará almacenamiento de imágenes, videos u otros archivos?",
    options: [
      { label: "Sí", value: 2250000 },
      { label: "No", value: 150000 },
    ],
  },
];

export const Calculator = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [formVisible, setFormVisible] = useState(false);
  const [userData, setUserData] = useState({ name: "", email: "" });
  const [showCost, setShowCost] = useState(false);

  const COSTO_BASE = 500000;

  const handleOptionSelect = (value) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[currentStep] = value || 0;
    setSelectedOptions(newSelectedOptions);

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      const total = newSelectedOptions.reduce(
        (acc, curr) => acc + (curr || 0),
        COSTO_BASE
      );
      setTotalCost(total);
      setFormVisible(true);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: userData.name,
      email: userData.email,
      totalCost: totalCost.toLocaleString(),
      answers: selectedOptions
        .map(
          (option, index) =>
            `${steps[index].question}: ${
              steps[index].options.find((o) => o.value === option)?.label ||
              "No contestado"
            }`
        )
        .join("\n"),
    };

    emailjs
      .send(
        "service_hz3vepe", // Replace with your EmailJS service ID
        "template_7j1xzub", // Replace with your EmailJS template ID
        templateParams,
        "vEpAM_4evm5xbt-Zm" // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log("Email enviado:", result.text);
          setShowCost(true);
        },
        (error) => {
          console.error("Error al enviar email:", error.text);
        }
      );
  };

  const resetCalculator = () => {
    setCurrentStep(0);
    setSelectedOptions([]);
    setTotalCost(0);
    setFormVisible(false);
    setUserData({ name: "", email: "" });
    setShowCost(false);
  };

  return (
    <CalculatorContainer id="calculator">
      <Title>Calculadora de Costos de Aplicaciones</Title>
      {!formVisible ? (
        <StepContainer>
          <Question>{steps[currentStep].question}</Question>
          <Options>
            {steps[currentStep].options.map((option, index) => (
              <OptionButton
                key={index}
                onClick={() => handleOptionSelect(option.value)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                className={
                  selectedOptions[currentStep] === option.value
                    ? "selected"
                    : ""
                }
              >
                {option.label}
              </OptionButton>
            ))}
          </Options>
        </StepContainer>
      ) : !showCost ? (
        <ContactForm onSubmit={handleFormSubmit}>
          <Input
            type="text"
            placeholder="Tu nombre"
            required
            value={userData.name}
            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
          />
          <Input
            type="email"
            placeholder="Tu correo electrónico"
            required
            value={userData.email}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
          />
          <SubmitButton type="submit">Ver Costo</SubmitButton>
        </ContactForm>
      ) : (
        <Summary>
          <p>
            El costo aproximado de tu aplicación es: $
            {totalCost.toLocaleString()} CLP
          </p>
          <ResetButton onClick={resetCalculator}>Calcular de nuevo</ResetButton>
        </Summary>
      )}
    </CalculatorContainer>
  );
};
